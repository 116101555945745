import React from 'react';

const PrivacyIcon = ({ isActive = true, variant = 'light' }) => (
  <svg width="31" height="36" viewBox="0 0 31 36" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M10.2 12.2041C10.2 9.4041 12.4 6.5041 15.2 6.5041C18 6.5041 20.2 8.7041 20.2 11.5041V14.3041H25.5V11.5041C25.5 5.8041 20.9 1.2041 15.2 1.2041C9.50001 1.2041 4.90002 5.8041 4.90002 11.5041V14.4041L10.1 14.0041L10.2 12.2041Z"
      fill={
        isActive
          ? '#B1D06A'
          : (variant === 'light' && '#5C5E7B') || (variant === 'dark' && '#898B9E')
      }
    />
    <path
      d="M10.1 12.204C10.1 10.604 10.7 9.00396 11.8 7.70396C12.3 7.00396 13 6.50396 13.9 6.10396C14.7 5.70396 15.7 5.60396 16.6 5.80396C18.5 6.10396 20.1 7.50396 20.8 9.20396C21 9.60396 21.1 10.104 21.1 10.604C21.2 11.104 21.1 11.604 21.1 11.904L20.9 14.304L20.2 13.604C22 13.704 23.7 13.904 25.5 13.904L25.1 14.304C25.1 12.704 25.2 11.104 24.9 9.60396C24.5 8.10396 23.8 6.80396 22.8 5.70396C20.8 3.50396 18 2.20396 15.1 2.30396C12.3 2.40396 9.6 3.80396 7.8 6.00396C6.9 7.10396 6.20004 8.40396 5.90004 9.80396C5.50004 11.204 5.50001 12.804 5.50001 14.404L4.90004 13.804C6.70004 13.904 8.40002 13.904 10.2 13.804L10.1 13.904V12.204ZM10.3 12.204V14.004V14.104H10.2C8.50002 14.304 6.70001 14.604 5.00001 14.904L4.40004 15.004V14.304L4.3 11.904C4.3 11.104 4.2 10.204 4.3 9.40396C4.4 7.70396 5.09999 6.00396 6.09999 4.60396C8.09999 1.70396 11.7 -0.0960379 15.2 0.00396207C18.7 0.00396207 22.2 1.80396 24 4.70396C24.9 6.10396 25.6 7.70396 25.8 9.30396C26.1 11.004 25.9 12.504 25.9 14.104V14.404H25.5C23.7 14.404 22 14.604 20.2 14.704H19.6L19.5 14.004L19.3 11.604C19.3 11.204 19.2 10.904 19.2 10.604C19.1 10.304 19 10.004 18.9 9.70396C18.4 8.50396 17.4 7.60396 16.1 7.20396C15.5 7.00396 14.8 6.90396 14.1 7.00396C13.4 7.10396 12.8 7.40396 12.2 7.90396C11.1 9.10396 10.3 10.604 10.3 12.204Z"
      fill="#33355A"
    />
    <path
      d="M26.7 34.604H3.59998C1.99998 34.604 0.799988 33.304 0.799988 31.804V17.404C0.799988 15.804 2.09998 14.604 3.59998 14.604H26.7C28.3 14.604 29.5 15.904 29.5 17.404V31.804C29.6 33.304 28.3 34.604 26.7 34.604Z"
      fill={
        isActive
          ? '#B1D06A'
          : (variant === 'light' && '#5C5E7B') || (variant === 'dark' && '#898B9E')
      }
    />
    <path
      d="M26.7 35.2038C19 35.4038 11.3 35.8038 3.5 35.6038C2.2 35.6038 1.00002 34.8038 0.400024 33.6038C0.100024 33.0038 0 32.4038 0 31.8038L0.0999756 30.4038C0.199976 28.5038 0.199988 26.5038 0.299988 24.6038C0.399988 22.7038 0.399988 20.7038 0.299988 18.8038V17.3038C0.299988 16.7038 0.499988 16.1038 0.799988 15.6038C1.39999 14.5038 2.59999 14.0038 3.79999 13.9038C11.5 13.5038 19.2 13.4038 27 13.4038C28.4 13.4038 29.7 14.2038 30.3 15.4038C30.6 16.0038 30.8 16.7038 30.8 17.3038V18.7038C30.8 20.6038 30.7 22.6038 30.6 24.5038L30.4 30.3038L30.3 31.8038C30.3 32.4038 30.1 33.0038 29.8 33.5038C29 34.5038 27.9 35.1038 26.7 35.2038ZM26.7 34.0038C27.5 34.0038 28.3 33.6038 28.6 32.9038C28.8 32.6038 28.9 32.2038 28.9 31.8038V30.4038L28.7 24.6038C28.6 22.7038 28.5 20.7038 28.5 18.8038V17.4038C28.5 17.1038 28.4 16.8038 28.3 16.6038C28 16.1038 27.5 15.7038 26.9 15.7038C19.3 15.7038 11.5 15.6038 3.79999 15.2038C2.99999 15.2038 2.30002 15.7038 1.90002 16.3038C1.70002 16.6038 1.59998 17.0038 1.59998 17.4038L1.5 18.8038C1.4 20.7038 1.5 22.7038 1.5 24.6038C1.5 26.5038 1.60001 28.5038 1.70001 30.4038L1.79999 31.8038C1.79999 32.2038 1.89998 32.5038 2.09998 32.8038C2.49998 33.4038 3.09999 33.7038 3.79999 33.7038C11.3 33.5038 19 33.8038 26.7 34.0038Z"
      fill="#33355A"
    />
    <path
      d="M15.2 25.4041C16.636 25.4041 17.8 24.24 17.8 22.8041C17.8 21.3682 16.636 20.2041 15.2 20.2041C13.7641 20.2041 12.6 21.3682 12.6 22.8041C12.6 24.24 13.7641 25.4041 15.2 25.4041Z"
      fill="#33355A"
    />
    <path d="M16.6 22.2041H13.9V29.3041H16.6V22.2041Z" fill="#33355A" />
  </svg>
);

export default PrivacyIcon;
